.custom_alert {
    margin-bottom: 0px;
    position: fixed;
    top: 5px;
    right: 5px;
    width: 300px;
    z-index: 9999;
    animation: fadeInLeft 1s ease-in-out;
}

.day-underline {
    border-bottom: 3px solid;
}

.exc_p {
    margin-bottom: 10px;
}

.loader_custom span {
    margin: auto;
    display: block;
}

span.show-error {
    color: red;
    font-size: 12px;
}

.modal_test.vedio_modal .modal-dialog {
    max-width: 900px;
}

.modal_test.vedio_modal .modal-content .modal-body.modal-body {
    padding: 0px;
    background: transparent;
    border: 0px;
}

.modal_test.vedio_modal .modal-content .close {
    color: #fff;
    right: -10px;
    position: absolute;
    top: -30px
}

.skip-test-error {
    margin-top: -20px;
    margin-bottom: 20px;
}